import { computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import EventBus from "@/helpers/event-bus";
import securePay from "@/composables/securePay";

export function useSubmitOrder(module = "cart") {
  const store = useStore();
  const router = useRouter();

  const additionalPayment = computed(() => {
    return store.getters[`${module}/additionalPayment`];
  });

  const securepayPaymentType = computed(() => {
    return store.getters["securepay/paymentType"];
  });

  const pointsDiscount = computed(() => {
    return store.getters[`${module}/pointsDiscount`];
  });

  const voucherActionType = computed(() => {
    return store.getters[`${module}/voucherActionType`];
  });

  const redirectToOrderSuccessPage = (orderNumber, module) => {
    router.push({
      name: "order-success",
      params: { order_number: orderNumber, module: module },
    });
  };

  const redirectToCheckoutFailure = () => {
    router.push({
      name: "order-fail",
      params: {},
    });
  };

  const setNotification = (code = 'ORDER_RESPONSE') => {
    let notification = {
      code: code,
      type: "error",
    };
    store.dispatch("notification/set", notification);
  };

  const submitOrder = async (orderData) => {
    // Check first if the user has selected a payment method
    if (additionalPayment.value > 0 && securepayPaymentType.value == "") {
      store.dispatch("apiLoading/setLoading", true);
      securePay.sendPostMessage("payment");
      securePay.handleViewportDimension();
      return;
    }

    // Can't complete an order if no points were redeemed
    if (pointsDiscount.value <= 0 && voucherActionType.value != "Fixed") {
      return;
    }

    let order = await store.dispatch(`${module}/submitOrder`, orderData);
    EventBus.emit("submitOrderResponse", order);
    // Handle the response
    if (parseInt(order.order_number) > 0 && order.success) {
      // If it's an order without additional payment then redirect to the success page
      redirectToOrderSuccessPage(order.order_number, `${module}`);
    } else if (order.order_number == null && order.success) {
      //Redirect to the payment method page
      location.href = order.url;
    } else {
      if (order.validation_error != null && order.validation_error != "") {
        if (order.validation_error === "FF") {
          redirectToCheckoutFailure();
          // Validation error for Domain lock check
        } else if (order.validation_error === "VF") {
          setNotification('VALIDATION_ERROR');
        } else {
          setNotification('ORDER_RESPONSE');
        }
      }
    }
  };

  return {
    submitOrder,
  };
}
