<template>
      <section class="sticky-summary">
            <div class="checkout__table">
                  <div>{{ t("LBL_SUBTOTAL") }}</div>
                  <div class="text-right type-caption">{{ totalSumWithVoucherDiscount }} °P</div>

                  <div class="col-span-2">
                        <Voucher></Voucher>
                  </div>

                  <div class="border-t border-dotted border-gray-light col-span-2"></div>

                  <div class="type-number">
                        {{ t("LBL_TOTAL") }}
                  </div>
                  <div class="text-right type-number">{{ grandTotalWithVoucherDiscount }} °P</div>
                  <div class="flex flex-col justify-center items-start text-left col-span-2">{{
                        t("LBL_POINTS_TO_REDEEM") }}</div>
                  <div class="text-right type-caption col-span-2">
                        <div class="checkout__table-redeemVoucherTable">
                              <div class="col-span-2">
                                    <text-input cssClass="points-input" :key="grandTotalWithVoucherDiscount"
                                          :value="customerPoints < grandTotalWithVoucherDiscount ? customerPoints : pointsDiscount"
                                          @input="getInput($event)" :numberOnly="true"></text-input>
                              </div>
                              <div class="text-blue col-span-2">
                                    <Button :css-class="'green'" :text="'OK'"
                                          class="checkout__table-redeemVoucherTable_redeemButton"
                                          @click="onUserPointsRedeem" :disabled="isValid"></Button>
                              </div>
                        </div>
                  </div>
                  <div v-if="alertText" class="text-red-dark col-span-2">
                        {{ alertText }}
                  </div>
                  <div>{{ t("LBL_CO_PAYMENT") }}</div>
                  <div v-if="isValid || pointsDiscount" class="text-right type-caption"
                        v-html="$filters.formatAmount(additionalPayment, 'EUR')"></div>
                  <div
                        class="border-t border-dotted border-gray-light col-span-2 flex flex-col justify-center items-center">
                        <div v-if="cart?.items?.[0]?.product_group_name === 'PAYBACK_ARTICLE'">
                              <div class="pt-2 pb-2 type-caption">
                                    <input type="checkbox" id="termsCheckbox" v-model="termsAccepted"
                                          name="termsAccepted" class="mr-2" @change="onCheckboxChange">
                                    <label for="termsCheckbox">Ich akzeptiere die <a class="text-blue"
                                                href="/praemien/praemienaktionen/gutschein-agb" target="_blank"
                                                rel="noopener">Allgemeinen
                                                Geschäftsbedingungen zum Erwerb von
                                                Gutscheinen im PAYBACK Prämienshop</a> der Payback GmbH („AGB“).
                                          <br>
                                          <span>Informationen zu Ihrem Widerrufsrecht finden Sie <a class="text-blue"
                                                      href="/praemien/praemienaktionen/gutschein-widerrufsbelehrung"
                                                      target="_blank" rel="noopener">hier.</a> bzw.
                                                in den oben genannten AGB.</span></label>
                              </div>
                              <div class="pt-2 pb-2 type-caption">
                                    <input type="checkbox" id="conditionsCheckbox" v-model="conditionsAccepted"
                                          name="conditionsAccepted" class="mr-2" @change="onCheckboxChange">
                                    <label for="conditionsCheckbox">Ich stimme in Bezug auf den Gutscheinerwerb
                                          ausdrücklich zu,
                                          dass die Payback GmbH vor Ablauf der Widerrufsfrist mit der Ausführung des
                                          Vertrags beginnt.
                                          Mir ist bekannt, dass durch diese Zustimmung mit Beginn der Vertragserfüllung
                                          mein
                                          Widerrufsrecht erlischt.</label>
                              </div>
                              <div class="errorMessage text-red my-2 text-xs" v-show="!areBothCheckboxesChecked">Bitte
                                    akzeptieren Sie
                                    die AGB und Widerrufsbelehrung der PAYBACK Austria GmbH, um fortzufahren.</div>
                              <Button :css-class="'green'" :text="t('LBL_BUY_NOW')" class="w-full"
                                    @click="submitOrder()"
                                    :disabled="!areBothCheckboxesChecked || !isValid || isDisabled"
                                    :readonly="loading"></Button>
                              <span class="flex pt-3">Der Verkauf der Gutschein Bestellung erfolgt durch die PAYBACK
                                    GmbH.</span>
                              <a class="text-blue" href="https://www.payback.at/datenschutz" target="_blank"
                                    rel="noopener">Datenschutzhinweise.</a>
                        </div>
                        <div v-else>
                              <div class="pt-2 pb-2 type-caption">
                                    Für Ihre Bestellungen gelten die
                                    <a class="text-blue" href="/praemien/praemienaktionen/praemien-agb" target="_blank"
                                          rel="noopener">AGB der cadooz rewards GmbH.</a>
                                    Details zu Ihrem Widerrufsrecht finden Sie
                                    <a class="text-blue" href="/praemien/praemienaktionen/widerrufsbelehrung"
                                          target="_blank" rel="noopener">hier.</a>
                              </div>
                              <Button :css-class="'green'" :text="t('LBL_BUY_NOW')" class="w-full"
                                    @click="submitOrder()" :disabled="!isValid" :readonly="loading"></Button>
                              <div class="pt-2 pb-2 type-caption">
                                    Der Verkauf und Versand Ihrer Prämienbestellung erfolgt durch die cadooz rewards
                                    GmbH. Wenn Sie auf den Button „Jetzt kaufen” klicken, wird PAYBACK Ihre Bestellung
                                    an die cadooz rewards GmbH weiterleiten. Einzelheiten finden Sie in den
                                    <a class="text-blue" href="https://www.payback.at/datenschutz" target="_blank"
                                          rel="noopener">Datenschutzhinweisen.</a>
                              </div>
                        </div>
                  </div>
            </div>
            <!-- Commented trusted for now - we may need this here in future -->
            <!-- <div id="payBackTrustbadge" class="flex justify-center bg-light"></div> -->
            <div>
                  <app-image :alt="separation - border" class="roughBorder"
                        :src="require('../../assets/img/rough-border.png')"></app-image>
            </div>
            <div class="continue-shopping">
                  <router-link to="/" :title="t('LBL_CONTINUE_SHOPPING')"><svg width="24" height="24"
                              viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M15.41 7.41L14 6L8 12L14 18L15.41 16.59L10.83 12L15.41 7.41Z" fill="#0046AA" />
                        </svg>{{ ` ${t("LBL_CONTINUE_SHOPPING")}` }}</router-link>
            </div>
      </section>
</template>

<script>
import Button from "@/components/Button/Button";
import TextInput from "@/components/Inputs/TextInput";
import Voucher from "@/components/ShoppingCart/Voucher";
import AppImage from "@/components/Image/Image";
import { computed, ref, onMounted } from "vue";
import { useCheckout } from "@/composables/useCheckout";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import EventBus from "@/helpers/event-bus";

export default {
      name: "ExpressCheckout",
      components: {
            Button,
            TextInput,
            Voucher,
            AppImage
      },
      setup(props, { emit }) {
            const { t } = useI18n();
            const store = useStore();
            const inputValUpdated = ref(false);
            const termsAccepted = ref(false);
            const conditionsAccepted = ref(false);

            EventBus.on("updatedAlertText", (arg) => {
                  alertText.value = arg.alertText;
                  isValid.value = arg.isValid;
            });
            EventBus.on("submitOrderResponse", (arg) => {
                  isValid.value = arg?.success;
            });

            const { alertText, isValidPointsAmount, applicableDiscount, initUserInputValue, onPointsRedeem, isValid } = useCheckout("expressCheckoutCart");

            const userPointsInput = ref("");

            const totalSumWithVoucherDiscount = computed(() => {
                  return store.getters["expressCheckoutCart/totalSumWithVoucherDiscount"];
            });

            const cart = computed(() => {
                  return store.getters["expressCheckoutCart/cart"];
            });

            const additionalPayment = computed(() => {
                  return store.getters["expressCheckoutCart/additionalPayment"];
            });

            const pointsDiscount = computed(() => {
                  return store.getters["expressCheckoutCart/pointsDiscount"];
            });

            const grandTotalWithVoucherDiscount = computed(() => {
                  return store.getters["expressCheckoutCart/grandTotalWithVoucherDiscount"];
            });

            const voucherActionType = computed(() => {
                  return store.getters["expressCheckoutCart/voucherActionType"];
            });

            const loading = computed(() => {
                  return store.getters["apiLoading/getLoading"];
            });

            const onCheckboxChange = (event) => {
                  if (event.target.name == "termsAccepted") {
                        termsAccepted.value = event.currentTarget.checked;
                  } else {
                        conditionsAccepted.value = event.currentTarget.checked;
                  }
            };

            const submitOrder = async () => {
                  // inform the parent component that the submit order button was clicked
                  if (pointsDiscount.value > 0 || voucherActionType.value === "Fixed") {
                        emit("submitOrderButtonClicked");
                  } else {
                        setNotification();
                  }
            };
            const setNotification = () => {
                  let notification = {
                        code: "ORDER_RESPONSE",
                        type: "error"
                  };
                  store.dispatch("notification/set", notification);
            };
            const getInput = (input) => {
                  isValid.value = false;
                  input = parseInt(input.target.value);

                  if (isNaN(input) || input == "") {
                        input = 0;
                  }
                  userPointsInput.value = input;
                  inputValUpdated.value = true;
            };
            const customerPoints = computed(() => {
                  return store.getters["auth/customerPoints"];
            });
            const onUserPointsRedeem = async () => {
                  await onPointsRedeem(userPointsInput.value, true);
            };
            // <!-- Commented trusted for now - we may need this here in future -->
            // const initTrustedBadger = () => {
            //   _tsConfig = {
            //     yOffset: "0" /* offset from page bottom */,
            //     variant: "reviews" /* default, reviews, custom, custom_reviews */,
            //     customElementId: "" /* required for variants custom and custom_reviews */,
            //     trustcardDirection: "" /* for custom variants: topRight, topLeft, bottomRight, bottomLeft */,
            //     customBadgeWidth: "" /* for custom variants: 40 - 90 (in pixels) */,
            //     customBadgeHeight: "" /* for custom variants: 40 - 90 (in pixels) */,
            //     disableResponsive: "false" /* deactivate responsive behaviour */,
            //     disableTrustbadge: "false" /* deactivate trustbadge */,
            //   };
            //   let _tsid = "X1B804ABF1400BAB98DCD909EAAD13EC5";
            //   let _ts = document.createElement("script");
            //   _ts.type = "text/javascript";
            //   _ts.charset = "utf-8";
            //   _ts.async = true;
            //   _ts.src = "//widgets.trustedshops.com/js/" + _tsid + ".js";
            //   let __ts = document.getElementsByTagName("script")[0];
            //   __ts.parentNode.insertBefore(_ts, __ts);
            // };

            const areBothCheckboxesChecked = computed(() => {
                  return termsAccepted.value && conditionsAccepted.value;
            })

            onMounted(async () => {
                  if (additionalPayment.value > 0) {
                        await initUserInputValue(pointsDiscount.value);
                  } else {
                        if (customerPoints.value > grandTotalWithVoucherDiscount.value) {
                              await initUserInputValue(grandTotalWithVoucherDiscount.value);
                        } else {
                              await initUserInputValue(customerPoints.value);
                        }
                  }
                  // initTrustedBadger();
            });

            return {
                  getInput,
                  onUserPointsRedeem,
                  submitOrder,
                  inputValUpdated,
                  totalSumWithVoucherDiscount,
                  grandTotalWithVoucherDiscount,
                  additionalPayment,
                  isValidPointsAmount,
                  isValid,
                  applicableDiscount,
                  voucherActionType,
                  pointsDiscount,
                  customerPoints,
                  alertText,
                  t,
                  cart,
                  loading,
                  onCheckboxChange,
                  areBothCheckboxesChecked
            };
      }
};
</script>
<style lang="postcss" scoped>
.sticky-summary {
      top: 0;
      position: sticky;
}

.checkout__table {
      @apply grid grid-cols-2 gap-4 grid-flow-row-dense bg-white p-4 mt-1;

      &-redeemVoucherTable {
            @apply grid grid-cols-4 gap-0 grid-flow-row-dense bg-white;

            &_redeemButton {
                  width: 120px;
                  @apply ml-2;
            }
      }
}

.roughBorder {
      @apply w-full;
      height: 56px;
}

.continue-shopping {
      height: 2rem;
      margin-left: 0.5rem;
      @apply flex text-black flex-col justify-center items-start;

      a {
            @apply ml-0 text-blue flex-row flex items-center justify-start;
      }
}

.text-red-dark {
      overflow-wrap: anywhere;
}
</style>
