<template>
      <section>
            <nav-menu></nav-menu>
            <div v-if="cart?.items" class="page-content-lightestblue express">
                  <!-- <div v-if="cart?.error">
                        <div class="error-section">
                              <p>
                                    <b>Hinweis: </b>
                                    <span v-html="t('MSG_PAYMENT_ALERT')"></span>
                              </p>
                        </div>
                  </div> -->
                  <div class="page-content-lightestblue__container heading" v-if="cart.items.length > 0">
                        <h2>{{ t("LBL_ORDER_OVERVIEW") }}</h2>
                  </div>
                  <div class="page-content-lightestblue__container" v-if="cart.items.length > 0">
                        <div class="page-content-lightestblue__container__table">
                              <div class="page-content-lightestblue__container__table-colLeft">
                                    <Email :isShow="isShowEmail" @updateValue="onUpdateEmail" :email="orderData.email"></Email>
                                    <div>
                                          <div v-if="additionalPayment > 0">
                                                <SecurePay :mode="'express-checkout'" :requestType="'init'" :show="securepayPaymentType == ''"></SecurePay>
                                          </div>
                                    </div>
                                    <cart-products :expressCheckout="true"></cart-products>
                              </div>
                              <div class="page-content-lightestblue-main__container__table-colRight h-full">
                                    <express-checkout @submitOrderButtonClicked="onSubmitOrder"></express-checkout>
                              </div>
                        </div>
                  </div>
                  <div v-else><empty-cart></empty-cart></div>
            </div>
      </section>
</template>
<script>
      import CartProducts from "@/components/ShoppingCart/CartProducts";
      import ExpressCheckout from "@/components/ShoppingCart/ExpressCheckout";
      import NavMenu from "@/components/Menu/NavMenu";
      import { computed, ref, reactive, onMounted, watch } from "vue";
      import { useStore } from "vuex";
      import { useI18n } from "vue-i18n";
      import { useSubmitOrder } from "@/composables/useSubmitOrder";
      import SecurePay from "@/components/ShoppingCart/SecurePay";
      import EmptyCart from "@/components/ShoppingCart/EmptyCart";
      import Email from "@/components/Address/Email";

      export default {
            name: "ExpressCheckoutOrder",
            components: {
                  CartProducts,
                  SecurePay,
                  ExpressCheckout,
                  NavMenu,
                  EmptyCart,
                  Email
            },
            setup() {
                  const store = useStore();
                  const { t } = useI18n();
                  const { submitOrder } = useSubmitOrder("expressCheckoutCart");
                  const isShowEmail = ref(true);
                  const orderData = reactive({});

                  const cart = computed(() => {
                        return store.getters["expressCheckoutCart/cart"];
                  });

                  const additionalPayment = computed(() => {
                        return store.getters["expressCheckoutCart/additionalPayment"];
                  });

                  const securepayPaymentType = computed(() => {
                        return store.getters["securepay/paymentType"];
                  });

                  const email = computed(() => {
                        return store.getters["account/email"];
                  });

                  const onUpdateEmail = async (value) => {
                        orderData.email = value;
                  };

                  const onSubmitOrder = async () => {
                        // Call the submitOrder in the composable
                        await submitOrder(orderData);
                  };

                  onMounted(async () => {
                        await store.dispatch("expressCheckoutCart/get");
                        orderData.email = email.value;
                  });

                  watch(
                        () => securepayPaymentType.value,
                        async (current) => {
                              if (current != "") {
                                    await onSubmitOrder();
                              }
                        }
                  );
                  return {
                        onSubmitOrder,
                        onUpdateEmail,
                        securepayPaymentType,
                        isShowEmail,
                        additionalPayment,
                        orderData,
                        cart,
                        t
                  };
            }
      };
</script>

<style lang="postcss" scoped>
      .page-content-lightestblue {
            &__container {
                  h2 {
                        @apply mt-5;
                        @media screen and (max-width: 767px) {
                              @apply mx-2;
                        }
                  }
                  &.heading {
                        @apply text-left flex justify-start px-2;
                  }
                  @media screen and (max-width: 767px) {
                        width: 100%;
                  }
            }
            .error-section {
                  display: flex;
                  justify-content: center;
                  background: #fffae6;
                  height: 6rem;
                  align-items: center;
            }
            @media screen and (max-width: 767px) {
                  .error-section {
                        padding: 2rem;
                        height: auto;
                  }
            }
      }
</style>
